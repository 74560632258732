@tailwind base;
@tailwind components;
@tailwind utilities;


.playboard {
    flex-direction: column;
    width: 20rem;
    align-items: center;
    margin: 0.25rem auto;
    flex: auto;
    padding-top: .625rem;
    padding-bottom: .625rem;
}

@keyframes shake {
    10%, 90% {
      transform: translate3d(-3px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(5px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-6px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(6px, 0, 0);
    }
  }

.shakeboard {
    /* Start the shake animation and make the animation last for 0.5 seconds */
    animation: shake 0.5s;
  
    /* When the animation is finished, start again */
    animation-iteration-count: infinite;
  }